import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Marquee from 'react-fast-marquee'

const ClientsMarquee = () => {
  const data = useStaticQuery(graphql`
    query {
      webiny {
        listClients(sort: clientProjectDateCompleted_DESC, limit: 20) {
          data {
            clientLogo
            clientName
            createdOn
            clientShortDescription
            id
          }
        }
      }
    }
  `)
  
  const clients = data.webiny.listClients.data

  return (
    <div className="z-10 mb-10 md:mb-20">
      
      <div className="py-5">
        <Marquee>
            {clients.map((client) => (
            <div className="w-36 mx-0 grayscale md:w-48 md:mx-10">
              <img 
                alt={client.clientName}
                title={client.clientName}
                src={client.clientLogo}
              />
              </div>
            ))}
        </Marquee>
      </div>
    </div>
  )
}

export default ClientsMarquee