import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

const FaqAccordionStatic = () => {

  const faqs = [
    {
      id: '1',
      question: 'How much does your web design service cost?',
      answer:
        'Our web design service pricing varies depending on the project scope and requirements. Please contact us to get a quote.',
    },
    {
      id: '2',
      question: 'What is your web design process?',
      answer:
        'Our web design process includes discovery, design, development, testing, and launch. We work closely with our clients throughout the process to ensure their satisfaction.',
    },
    {
      id: '3',
        question: 'What is Shopify and how can it help my business?',
        answer: 'Shopify is a cloud-based eCommerce platform that allows businesses to create an online store to sell their products. Using Shopify, businesses can expand their reach to a wider audience and increase their sales and revenue.',
    },
    {
      id: '4',
        question: 'Why should my business consider hiring a Shopify eCommerce agency?',
        answer: 'Hiring a Shopify eCommerce agency can offer multiple benefits for your business. Our agency can help your business create a custom Shopify store that represents your brand and fulfills your specific needs. We can also provide guidance and support for optimizing your store for conversions, improving your search engine rankings, and ultimately increasing your overall sales and revenue.',
    },
    {
      id: '5',
        question: 'What should my business look for when selecting a Shopify eCommerce agency?',
        answer: ' When choosing a Shopify eCommerce agency, your business should consider their experience and expertise, as well as their portfolio of previous work. It\'s also crucial to look for an agency that offers a range of services, including website design, development, and optimization, as well as marketing and branding services. Additionally, you should consider their pricing and communication style to ensure they are a good fit for your business.',
    },
    {
      id: '6',
        question: 'How much would it cost my business to hire a Shopify eCommerce agency?',
        answer: 'The cost of hiring a Shopify eCommerce agency can vary depending on the size and complexity of your project, the level of customization required, and the specific services you need. Our agency will work with your business to determine a cost-effective solution that meets your needs and budget.',
    },
    {
      id: '7',
        question: 'How long does it typically take to build a Shopify store?',
        answer: 'The time it takes to build a Shopify store depends on the complexity of the project and the specific requirements of your business. Generally, it can take anywhere from a few weeks to a few months to build a typical Shopify store, depending on the level of customization required and the amount of support needed from our agency. We will work with your business to establish a timeline that meets your needs.',
    },
    {
      id: '8',
      question: 'Do you offer search engine optimization services?',
      answer:
        'Yes, we offer search engine optimization services to improve the visibility and ranking of your website in search engine results pages.',
    },
    {
      id: '9',
      question: 'What content management system do you use?',
      answer:
        'We specialize in using headless CMS architectures to build websites and applications. Our team has expertise in working with popular headless CMS platforms such as Contentful, Strapi, Sanity, Prismic, DatoCMS, Webiny, and TinaCMS. While we have experience working with traditional CMS systems like WordPress and Drupal, we believe that headless CMS architectures offer greater flexibility and scalability for modern web development. However, we are always willing to work with the CMS that best meets your needs and requirements.',
    },
    {
      id: '10',
      question: 'What kind of support do you offer after the website is launched?',
      answer:
        'We offer ongoing support after the website is launched to ensure that it remains up-to-date and secure.',
    },
    // {
    //   id: '8',
    //   question: 'Do you provide website maintenance services?',
    //   answer:
    //     'Yes, we offer website maintenance services to ensure that your website stays up-to-date and secure.',
    // },
    // {
    //   id: '9',
    //   question: 'How long does it take to design a website?',
    //   answer:
    //     'The time it takes to design a website depends on the project scope and requirements. Typically, a website can be designed within 4 to 12 weeks.',
    // },
    // {
    //   id: '10',
    //   question: 'Do you offer website hosting services?',
    //   answer:
    //     'Yes, we offer website hosting services to ensure that your website is always up and running.',
    // },
    // {
    //   id: '11',
    //   question: 'Can you redesign an existing website?',
    //   answer:
    //     'Yes, we can redesign an existing website to give it a fresh look and improve its functionality.',
    // },
    // {
    //   id: '12',
    //   question: 'What is your payment process?',
    //   answer:
    //     'Our payment process typically involves a deposit at the start of the project and then additional payments as milestones are reached.',
    // },
    // {
    //   id: '15',
    //   question: 'Do you offer website design templates?',
    //   answer:
    //     'Yes, we offer website design templates that can be customized to fit your specific needs.',
    // },
    // {
    //   id: '18',
    //   question: 'Do you offer mobile responsive design?',
    //   answer:
    //     'Yes, we offer mobile responsive design to ensure that your website looks great on all devices.',
    // },
    // {
    //   id: '19',
    //   question: 'Can you help me with website copywriting?',
    //   answer:
    //     'Yes, we offer website copywriting services to ensure that your website content is clear, concise, and engaging.',
    // },
    // {
    //   id: '20',
    //   question: 'What is your experience with eCommerce websites?',
    //   answer:
    //     'We have extensive experience in designing and developing eCommerce websites that are secure, user-friendly, and optimized for sales.',
    // },
    // {
    //   id: '16',
    //   question: 'Can you integrate my website with social media?',
    //   answer:
    //     'Yes, we can integrate your website with social media platforms to increase your online presence and engagement.',
    // },
  ];

  return (
    <div className="px-10 md:w-3/4 mx-auto mb-20">
    <h2 className='text-2xl font-semibold lg:text-3xl mb-10 md:mb-0 text-bradient-blue'>Frequently Asked Questions</h2>
    <Accordion
        allowMultipleExpanded={false}
        allowZeroExpanded
        className="my-4 border-b border-neutral-300"
    >
        {faqs.map(faq => {
        return (
            <AccordionItem key={faq.id}>
            <AccordionItemHeading className="border-t font-semibold border-neutral-200">
                <AccordionItemState>
                {({ expanded }) => (expanded ? 
                    <AccordionItemButton className="py-2" itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
                    <h3 className='text-base text-md md:text-lg py-3 m-0 pr-6 lg:pr-0 relative' itemProp="name">
                        {faq.question}
                        <svg class="-rotate-90 float-right absolute right-0 top-5" width="21" height="10" viewBox="0 0 21 10" fill="#022640" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.1716 0.146445L20.3536 4.32843C20.5488 4.52369 20.5488 4.84027 20.3536 5.03553L16.1716 9.21751C15.9763 9.41277 15.6597 9.41277 15.4645 9.21751C15.2692 9.02225 15.2692 8.70567 15.4645 8.51041L18.7929 5.18198L4.53023e-07 5.18198L3.656e-07 4.18198L18.7929 4.18198L15.4645 0.853552C15.2692 0.65829 15.2692 0.341708 15.4645 0.146445C15.6597 -0.0488169 15.9763 -0.0488169 16.1716 0.146445Z" fill="#022640"></path></svg>
                    </h3>
                    </AccordionItemButton>
                :
                    <AccordionItemButton className="py-2" itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
                    <h3 className='text-base text-md md:text-lg py-3 m-0 pr-6 lg:pr-0 relative' itemProp="name">
                        {faq.question}
                        <svg class="rotate-90 float-right absolute right-0 top-5" width="21" height="10" viewBox="0 0 21 10" fill="#022640" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.1716 0.146445L20.3536 4.32843C20.5488 4.52369 20.5488 4.84027 20.3536 5.03553L16.1716 9.21751C15.9763 9.41277 15.6597 9.41277 15.4645 9.21751C15.2692 9.02225 15.2692 8.70567 15.4645 8.51041L18.7929 5.18198L4.53023e-07 5.18198L3.656e-07 4.18198L18.7929 4.18198L15.4645 0.853552C15.2692 0.65829 15.2692 0.341708 15.4645 0.146445C15.6597 -0.0488169 15.9763 -0.0488169 16.1716 0.146445Z" fill="#022640"></path></svg>
                    </h3>
                    </AccordionItemButton>
                )}
                </AccordionItemState>
            </AccordionItemHeading>
            <AccordionItemPanel itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                <p className="leading-5 text-sm lg:text-md text-bradient-blue pt-5 pb-10 lg:pr-5 m-0" itemProp="text">
                {faq.answer}
                </p>
            </AccordionItemPanel>
            </AccordionItem>
        );
        })}
    </Accordion>
    </div>

  );
};

export default FaqAccordionStatic;