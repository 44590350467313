import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Typewriter from 'typewriter-effect'

import HomePageHero from '../components/homePageHero'
import HeaderMenu from '../components/headerMenu'
import ClientsMarquee from '../components/clientsMarquee'
import Footer from '../components/footer'
import Helmet from 'react-helmet'
import Seo from '../components/seo'
import FaqAccordionStatic from '../components/faqAccordionStatic'
import ogImageMain from '../assets/images/1200x628-logo-banner.jpg'
import LetsTalk from "../components/letstalk"
import ClientSwiperCards from "../components/clientSwiperCards"


export default function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return (
    <div>
    <Seo title="BRADIENT" />
    <Helmet
        bodyAttributes={{
            class: 'home-custom'
        }}
    />
    <Helmet>
      <meta property="fb:app_id" content='2970238806604187' />
      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={data.site.siteMetadata.title} />
      <meta property='og:description' content={data.site.siteMetadata.description} />
      <meta property='og:url' content={data.site.siteMetadata.siteUrl} />
      <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}` + `${ogImageMain}`} />
    </Helmet>
    <HeaderMenu />
    <HomePageHero />


    <div className='w-full inline-block'>
      <div className='container mx-auto'>
        <div className='grid grid-cols-1 md:grid-cols-12 py-10 px-10 md:p-0 md:gap-10 md:py-20'>
          <div className="col-span-6">
            <ClientSwiperCards />
          </div>
          <div className='col-span-6 pt-5 md:pt-0 lg:p-10 flex items-center justify-center'>
            <div className=''>
              <h2 className='text-2xl font-semibold lg:text-3xl md:mb-0 text-black'>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                  onInit={(typewriter) => {

                    typewriter.typeString('Shopify Developer')
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {
                      })
                      .start();
                    typewriter.typeString('SEO Optimization')
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {})
                      .start();
                    typewriter.typeString('GenAI Development Services')
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {})
                      .start();
                    typewriter.typeString('Digital Marketing Strategy')
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {})
                      .start();
                    typewriter.typeString('GenAI Prompt Engineering Services')
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {
                      })
                      .start();
                    typewriter.typeString('Social Media Management')
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {})
                      .start();
                    typewriter.typeString('Startup Web Design')
                      .pauseFor(2500)
                      .deleteAll()
                      .callFunction(() => {})
                      .start();
                  }}
                /></h2>
              <p className='leading-5 text-sm lg:text-md my-3 lg:max-w-2xl text-black'>We employ a strategic approach that combines in-depth market analysis with innovative design principles. Our aim is to create digital experiences that generate measurable value for both consumers and businesses. We excel in developing sophisticated, visually striking web platforms that enhance brand identity, optimize user engagement, and drive conversion rates. Our solutions are meticulously crafted to align with each client's unique objectives and industry requirements.</p>
              {/* 
              <span className='bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-gray-900 via-gray-100 to-gray-900 animate-gradient-x text-transparent bg-clip-text drop-shadow-sm'></span> 
              <p className='text-sm mt-2 mb-5'>Web design and eCommerce agency based in Los Angeles, we specialize in creating professional and visually appealing websites for our clients. We have extensive experience working with Shopify Plus.</p>
              <p className='text-sm mb-0'>Our team of web designers and developers have the skills and expertise to create custom eCommerce websites that are tailored to the needs of each individual business. We take the time to understand the unique requirements of our clients and create a website that not only looks great, but is also user-friendly and easy to navigate.</p> */}
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <ClientsMarquee />
    
    <FaqAccordionStatic />
    
    <LetsTalk />

    <Footer />
    </div>
  )
}
