import * as React from "react"
export default function LetsTalk({ }) {
  return (
    <div className=''>

    {/* <a href="/contact" className='border-t border-gray-200 w-full inline-block bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-gray-300 via-white to-gray-300 animate-gradient-fast md:animate-none md:bg-white md:from-transparent md:via-transparent md:to-transparent md:hover:animate-gradient-fast md:hover:from-gray-300 md:hover:via-white md:hover:to-gray-300 transition duration-500 ease-in-out'> */}
    <a href="/contact" className='w-full inline-block text-white hover:text-white bg-bradient-blue'>
      <div className='container mx-auto'>
        <div className='grid grid-cols-1 md:grid-cols-1 py-10 px-5 md:p-0 md:gap-10 md:py-16 lg:py-14'>
            <div className='text-center'>
              <h2 className='text-2xl font-semibold lg:text-3xl mb-4'>Have a Project in Mind?</h2>
              <span className='text-lg md:text-lg'>We're Here to Help</span>
              <p className="py-5">
                <a href="/contact">
                  <button className='block mx-auto text-md lg:text-lg antialiased text-md bg-trasparent hover:bg-white hover:text-bradient-blue border-2 font-semibold transition duration-200 ease-in-out text-center py-5 px-10 md:px-10 text-md mt-5 text-md bg-transparent border-white text-white'>Schedule a Consultation</button>
                </a>
              </p>  
            </div>
        </div>
      </div>
    </a>
    </div>
  )
}
